import {useEffect, useState} from 'react'
import {GetServerSideProps} from 'next'
import {useDispatch} from 'react-redux'
import {useRouter} from 'next/router'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {useTranslation} from 'next-i18next'
import Header from 'src/components/modules/globals/header/Header'
import Footer from 'src/components/modules/globals/footer/Footer'
import HeadContent from 'src/components/modules/globals/head/HeadContent'
import {publicVerifyUserApi} from 'src/services/api/auth'
import {publicGetPropertiesForHomePageApi} from 'src/services/api/property'
import {initPropertyState} from 'src/store/actions/propertySearchActions'
import {PropertyCardType} from 'src/types/property'
import {APP_SLUG, SLUG_MC} from 'src/constants/slug'
import {BASE_URL} from 'src/constants/route'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import {useAppContext} from 'src/context/AppProvider'
import HomeHero from 'src/components/modules/home/homeHero/HomeHero'
import InfoBoxes from 'src/components/modules/home/Info/InfoBoxes'

import Properties from 'src/components/modules/home/properties/Properties'
import DiscoverProperties from 'src/components/modules/home/discoverProperties/DiscoverProperties'
import {sleep, tryAsyncFunc} from 'src/utils/other'

const meta = {
  keywords: '',
}

const imageMaxWidth = APP_SLUG === SLUG_MC ? 770 : 400
const photosLimit = 10

export default function Home(props: {properties: PropertyCardType[]}) {
  const {properties: serverSideProperties} = props

  const {t} = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const {setModal} = useAppContext()
  const [properties, setProperties] = useState<PropertyCardType[]>(
    serverSideProperties || [],
  )

  const verifyToken = (router.query.verify_token as string) || undefined

  useEffect(() => {
    dispatch(initPropertyState())
  }, [dispatch])

  useEffect(() => {
    const totalMilliSecondsMinimum = window.innerWidth > 900 ? 1000 : 2000
    const totalMilliSecondsMaximum = 5000 //if after 5 seconds, onload is not triggered, we will call it anyway, since react router will ne trigger 'load' event

    let timeoutId: any = null
    const start = Date.now()

    const handleLoad = async () => {
      clearTimeout(timeoutId)
      // Perform actions after the component has fully loaded

      const end = Date.now()
      const totalMilliSeconds = end - start
      const difference = totalMilliSecondsMinimum - totalMilliSeconds

      //if we are under the minimum time, wait the difference
      if (difference > 0) {
        await sleep(difference)
      }

      //fetch all frontend
      const [properties, err] = await tryAsyncFunc(
        publicGetPropertiesForHomePageApi(photosLimit, imageMaxWidth),
      )

      if (err) {
        return
      }

      setProperties(properties)
    }

    try {
      //if already loaded (like in a next router redirection), call it right now
      if (window.document.readyState === 'complete') {
        handleLoad()
        return
      }
    } catch (e) {}

    window.addEventListener('load', handleLoad)

    timeoutId = setTimeout(handleLoad, totalMilliSecondsMaximum)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  useEffect(() => {
    if (verifyToken) {
      publicVerifyUserApi(verifyToken)
        .then((res) => {
          if (res.status === 200) {
            setModal('email-verified')
            //dont auto login
          }
        })
        .finally(() => {
          //remove token from url
          let newQuery = {
            ...router.query,
          }
          delete newQuery.verify_token
          router.replace(
            {pathname: router.pathname, query: newQuery},
            undefined,
            {
              shallow: true,
            },
          )
        })
    }
  }, [router, dispatch, setModal, verifyToken])

  return (
    <div>
      <HeadContent
        title={t('meta_title')}
        description={t('meta_description')}
        keywords={meta.keywords}
        imageUrl={`${BASE_URL}/images/home-hero.jpg`}
      />
      <Header />
      <HomeHero />
      <InfoBoxes />
      <div className="my-16" />
      <Properties properties={properties} />
      <DiscoverProperties />
      <Footer />
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const {locale, res} = context

  let properties: PropertyCardType[] = []

  const [data, err] = await tryAsyncFunc(
    publicGetPropertiesForHomePageApi(
      photosLimit,
      imageMaxWidth,
      6, //2x3 grid
    ),
  )

  if (!err && data) {
    properties = data
  }

  //if on serverside, set cache control to 1 minute, revalidate each 2 minute
  if (res) {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=6, stale-while-revalidate=10',
    )
  }

  return {
    props: {
      ...(await serverSideTranslations(locale ?? DEFAULT_LOCALE, [
        'common',
        'header',
        'hero',
        'footer',
        'home',
        'auth',
        'amenity',
        'property',
        'booking',
        'account',
        'inbox',
      ])),
      properties,
    },
  }
}
